<template>
  <div class="container">
    <b-row>
      <b-col sm="1">
        <b-button v-b-toggle.stats1 class="collbutton" @click="stats1 = !stats1">
          <b-icon-caret-right-fill v-if="stats1"></b-icon-caret-right-fill><b-icon-caret-down-fill v-else></b-icon-caret-down-fill
        ></b-button>
      </b-col>
      <b-col>
        <h2 class="p-2 my-2 colltext"><b-icon-graph-up></b-icon-graph-up> Stats</h2></b-col
      >
    </b-row>
    <b-collapse visible id="stats1">
      <b-row>
        <b-col sm="12" lg="9" class="my-4">
          <div>
            <canvas ref="piechart" class="mx-5 p-5"></canvas>
            <b-row>
              <b-col sm="12" lg="6" class="my-4"><canvas ref="piechart4" class="mini-canvas"></canvas></b-col>
              <b-col sm="12" lg="6" class="my-4"><canvas ref="piechart5" class="mini-canvas"></canvas></b-col>
            </b-row></div
        ></b-col>
        <b-col sm="12" lg="3" class="my-5">
          <div>
            <b-table id="userTable" hover :fields="fields" :items="items"> </b-table>
            <b-table id="bannedTable" hover :fields="bannedfields" :items="banned" v-if="banned.length !== 0">
              <template #cell(value)="data">
                <div class="d-flex align-items-center justify-content-end">
                  <div>
                    <b class="banned">{{ data.item.title }}</b
                    ><br />
                    <b>Banned on:</b> {{ timestampToDate(data.item.ban_date) }}
                  </div>
                  <img class="rounded mx-2" width="60" :src="data.item.value" />
                </div>
              </template>
            </b-table>
            <b-table id="bannedTableMonth" v-if="bannedMonth.length !== 0" hover per-page="5" :current-page="bannedPage" :fields="bannedMonthfields" :items="bannedMonth">
              <template #cell(value)="data">
                <div class="d-flex align-items-center justify-content-end">
                  <div>
                    <b class="banned">{{ data.item.title }}</b
                    ><br />
                    <b>Banned on:</b> {{ timestampToDate(data.item.ban_date) }}
                  </div>
                  <img class="rounded mx-2" width="60" :src="data.item.value" />
                </div>
              </template>
            </b-table>
            <b-pagination v-model="bannedPage" variant="dark" pills :total-rows="bannedMonth.length" per-page="5" aria-controls="bannedTableMonth"></b-pagination>
            Showing {{ (bannedPage - 1) * 5 + 1 }} to {{ bannedMonth.length > bannedPage * 5 ? bannedPage * 5 : bannedMonth.length }} of {{ bannedMonth.length }} entries
          </div>
        </b-col>
      </b-row>
    </b-collapse>
    <b-row>
      <b-col sm="1">
        <b-button v-b-toggle.stats2 class="collbutton" @click="stats2 = !stats2">
          <b-icon-caret-right-fill v-if="stats2"></b-icon-caret-right-fill><b-icon-caret-down-fill v-else></b-icon-caret-down-fill
        ></b-button>
      </b-col>
      <b-col>
        <h2 class="p-2 my-2 colltext"><b-icon-graph-up></b-icon-graph-up> Stats per map</h2></b-col
      >
    </b-row>
    <b-collapse id="stats2">
      <b-row>
        <b-col sm="12" lg="9" class="my-4"> <canvas ref="piechart2"></canvas></b-col>
        <b-col sm="12" lg="3" class="my-5">
          <b-table id="mapsTable" hover per-page="5" :current-page="mapsPage" :fields="mapsfields" :items="permaps">
            <template #cell(map_name)="data"
              ><div class="map">
                <img width="100" height="55" class="map_image" :src="data.item.map_image" />
                <span class="map_name">{{ data.item.map_name }}</span>
              </div>
            </template>
          </b-table>
          <b-pagination v-model="mapsPage" variant="dark" pills :current-page="mapsPage" :total-rows="permaps.length" per-page="5" aria-controls="mapsTable"></b-pagination>
          Showing {{ (mapsPage - 1) * 5 + 1 }} to {{ permaps.length > mapsPage * 5 ? mapsPage * 5 : permaps.length }} of {{ permaps.length }} entries
        </b-col>
      </b-row>
    </b-collapse>
    <b-row>
      <b-col sm="1">
        <b-button v-b-toggle.stats3 class="collbutton" @click="stats3 = !stats3">
          <b-icon-caret-right-fill v-if="stats3"></b-icon-caret-right-fill><b-icon-caret-down-fill v-else></b-icon-caret-down-fill
        ></b-button>
      </b-col>
      <b-col>
        <h2 class="p-2 my-2 colltext"><b-icon-graph-up></b-icon-graph-up> Stats per nationality</h2></b-col
      >
    </b-row>
    <b-collapse id="stats3">
      <b-row>
        <b-col sm="12" lg="9" class="my-4"> <canvas ref="piechart3"></canvas></b-col>
        <b-col sm="12" lg="3" class="my-5">
          <b-table id="nationalityTable" hover per-page="5" :current-page="nationalityPage" :fields="nationalityfields" :items="nationality">
            <template #cell(title)="data">
              <div class="d-flex align-items-center justify-content-start">
                <div>{{ data.item.title }}</div>
                <div class="px-2"><img class="flag" width="30" :src="flagImage(data.item.title)" /></div>
              </div>
            </template>
          </b-table>
          <b-pagination v-model="nationalityPage" variant="dark" pills :total-rows="nationality.length" per-page="5" aria-controls="nationalityTable"></b-pagination>
          Showing {{ (nationalityPage - 1) * 5 + 1 }} to {{ nationality.length > nationalityPage * 5 ? nationalityPage * 5 : nationality.length }} of {{ nationality.length }} entries
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import SteamService from "../services/SteamService";
import Chart from "chart.js/auto";
//const mongo = require("../mongodb");
export default {
  name: "App",
  data() {
    return {
      stats1: false,
      stats2: true,
      stats3: true,
      items: [],
      permaps: [],
      nationality: [],
      monthly: [],
      yearly: [],
      banned: [],
      bannedMonth: [],
      nationalityPage: 1,
      mapsPage: 1,
      bannedPage: 1,
      fields: [
        {
          key: "title",
          label: "",
          thClass: "bold middle right",
          tdClass: "bold middle right",
        },
        { key: "value", label: "Stats", tdClass: "middle", thClass: "bold middle right" },
      ],
      bannedfields: [{ key: "value", label: "Banned Today", tdClass: "middle right", thClass: "bold middle right" }],
      bannedMonthfields: [{ key: "value", label: "Banned (last 30 days)", tdClass: "middle right", thClass: "bold middle right" }],
      nationalityfields: [
        {
          key: "title",
          label: "Nationality",
          tdClass: "bold middle",
        },
        { key: "value", label: "Number of players", tdClass: "middle" },
      ],
      mapsfields: [
        {
          key: "map_name",
          label: "Map",
          tdClass: "bold middle right",
        },
        { key: "value", label: "Times played", tdClass: "middle" },
      ],
      currentPage: 1,
      totalRows: 0,
    };
  },

  created: function() {
    document.title = "Stats";
  },

  mounted: function() {
    this.$emit("startLoad", true);
    SteamService.getPlayersBannedToday().then((res) => {
      this.banned = res;
      this.$emit("startLoad", false);
    });
    SteamService.getPlayersBanned30().then((res) => {
      this.bannedMonth = res;
      this.$emit("startLoad", false);
    });
    SteamService.getMapsStats().then((res) => {
      this.permaps = res;
      this.$emit("startLoad", false);

      const config2 = {
        type: "doughnut",
        data: {
          labels: this.permaps.map((map) => map.map_name),
          datasets: [
            {
              label: "pergame",
              data: this.permaps.map((map) => map.value),
              backgroundColor: this.generateRandomColors(this.permaps.length),
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
        },
      };
      new Chart(this.$refs.piechart2, config2);
    });

    SteamService.getNationalityStats().then((res) => {
      this.nationality = res;
      this.$emit("startLoad", false);

      const config3 = {
        type: "doughnut",
        data: {
          labels: this.nationality.map((nat) => nat.title),
          datasets: [
            {
              label: "nationality",
              data: this.nationality.map((nat) => nat.value),
              backgroundColor: this.generateRandomColors(this.nationality.length),
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
        },
      };
      new Chart(this.$refs.piechart3, config3);
    });

    SteamService.getPlayersBannedMonthly().then((res) => {
      this.monthly = res;
      this.$emit("startLoad", false);

      const config4 = {
        type: "bar",
        data: {
          labels: this.monthly.map((monthly) => monthly.month),
          datasets: [
            {
              label: "Banned Players (Monthly)",
              data: this.monthly.map((monthly) => monthly.players),
              backgroundColor: this.generateRandomColors(this.monthly.length),
              axis: "y",
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          indexAxis: "y",
          layout: {
            padding: 30,
          },
        },
      };
      new Chart(this.$refs.piechart4, config4);
    });

    SteamService.getPlayersBannedYearly().then((res) => {
      this.yearly = res;
      this.$emit("startLoad", false);

      const config5 = {
        type: "bar",
        data: {
          labels: this.yearly.map((yearly) => yearly.year),
          datasets: [
            {
              label: "Banned Players (Yearly)",
              axis: "y",
              data: this.yearly.map((yearly) => yearly.players),
              backgroundColor: this.generateRandomColors(this.yearly.length),
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          indexAxis: "y",
          layout: {
            padding: 30,
          },
        },
      };
      new Chart(this.$refs.piechart5, config5);
    });

    SteamService.getStats().then((res) => {
      this.items = res;
      this.$emit("startLoad", false);
      const config = {
        type: "doughnut",
        data: {
          labels: this.items.map((user) => user.title).slice(5),
          datasets: [
            {
              label: "stats",
              data: this.items.map((user) => user.value).slice(5),
              backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 205, 86)"],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
        },
      };
      new Chart(this.$refs.piechart, config);
    });
  },

  computed: {},

  methods: {
    generateRandomColors(n) {
      let colorArray = [];
      for (let i = 0; i < n; i++) {
        const rand1 = Math.floor(Math.random() * 256);
        const rand2 = Math.floor(Math.random() * 256);
        const rand3 = Math.floor(Math.random() * 256);
        colorArray.push(`rgb(${rand1}, ${rand2}, ${rand3})`);
      }
      return colorArray;
    },
    flagImage(flag) {
      if (flag == "unknown") flag = "-";
      flag = flag.toLowerCase();
      return "https://steambanchecker.altervista.org/static/img/flags/" + flag + ".svg";
    },
    timestampToDate: (value) => {
      let a = new Date(value);
      let year = a.getFullYear();
      let month = a.getMonth() + 1 < 10 ? "0" + (a.getMonth() + 1) : a.getMonth() + 1;
      let date = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
      let formattedTime = date + "/" + month + "/" + year;
      return formattedTime;
    },
  },
};
</script>
<style scoped>
.container {
  margin-bottom: 5rem;
}

.collbutton {
  background: white;
  border: none;
  color: black;
  margin-top: 16px;
}

.banned {
  color: red;
}
.colltext {
  margin-left: -2%;
}

.map {
  position: relative;
}

.map_name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid #cccccc;
  background: #fff;
  font-weight: bold;
}

.map_image {
  border: 1px solid #cccccc;
}
</style>
